<template>
  <div>
    <v-container class="pa-0" fluid>

<!--      <v-row align="center" justify="center" class="pa-4 pb-0">
        <v-col sm="12" md="4" lg="4" xl="4" class="pb-0">
                    <v-text-field

            outlined
            label="search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>-->

      <v-row align="start" justify="center">
        <v-col sm="12" md="5" lg="4" xl="4">
          <v-carousel
              height="auto"
              cycle
          >
            <v-carousel-item
                v-for="(image,i) in product.images"
                :key="i"
            >
              <div v-if="image.src">

                  <v-img
                      contain
                      :src="image.src"
                      width="100%"
                      eager
                  />

              </div>
              <div v-else>
                <v-img
                    contain
                    :src="image.src"
                    width="100%"
                    eager
                />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col sm="12" md="5" lg="4" xl="4">

        <v-card
          outlined
          tile
          class="border-none shop-card">
          <v-card-title>{{ product.itemName }}</v-card-title>
<!--          <v-card-subtitle>テキストテキスト</v-card-subtitle>-->
          <v-card-subtitle class="s-price">¥{{product.price | toThousandFilter}}</v-card-subtitle>
          <v-card-text>
          <v-row align="start" justify="center" class="pa-3">

            <v-col sm="12" md="12" lg="12" xl="12" class="pa-0 btn-reserve">
              <v-btn
                    color="#e60044"
                    dark
                    depressed
                    tile
                    block
                    @click="addCart"

                >
                  購入する
              </v-btn>
             </v-col>
            </v-row>
</v-card-text>
<!--<v-card-text class="pt-0">
            <v-row align="start" justify="center">
            <v-col sm="6" md="6" lg="6" xl="6" class="pt-0 text-center">
              <v-btn
                    color="#e60044"
                    outlined
                    depressed
                    tile
                    block
                >
                  お気に入りに追加する
              </v-btn>
             </v-col>
            </v-row>
</v-card-text>-->
<v-card-text class="pt-0">
            <v-row align="start" justify="center">
            <v-col sm="12" md="12" lg="12" xl="12">
              <div class="wrap-movie" v-html="product.commentHtml"/>
             </v-col>
            </v-row>
</v-card-text>
<!--<v-card-text>
            <v-row align="start" justify="center" class="pa-3">
              <v-col sm="12" md="4" lg="4" xl="4" class="pa-0 text-center">
                <v-btn
                      color="#036EB8"
                      dark
                      depressed
                      tile
                      block
                      class="caption"
                  >
                    FACEBOOKでシェア
                </v-btn>
              </v-col>
              <v-col sm="12" md="4" lg="4" xl="4" class="pa-0 text-center">
                <v-btn
                      color="#2EA7E0"
                      dark
                      depressed
                      tile
                      block
                      class="caption"
                  >
                    TWITTERでシェア
                </v-btn>
              </v-col>
              <v-col sm="12" md="4" lg="4" xl="4" class="pa-0 text-center">
                <v-btn
                      color="#13AE67"
                      dark
                      depressed
                      tile
                      block
                      class="caption"
                  >
                    LINEで送る
                </v-btn>
              </v-col>
            </v-row>
                      </v-card-text>-->
        </v-card>

        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
  import shops from "@/api/shops.js";
  import products from "@/api/products";

  export default {
    props: {
      shopId: String,
      itemNum: String,
    },
    data() {
      return {

        // 店舗情報
        shop: [],

        // 商品情報
        product:[],
        productsQuery:{
          type:2,
          shop:null,
          itemNum:null,
        },

      }
    },
    created() {

      // 商品情報を取得
      this.productsQuery.shop = this.shopId
      this.productsQuery.itemNum = this.itemNum
      products.fetchList(this.productsInfo, this.productsQuery);

      // 店舗情報を取得
      shops.fetch(this.shopInfo,this.shopId)


    },
    methods :{
      /** ____________________________________________________________________ API 店舗情報から取得  */
      async shopInfo(info) {
        this.shop = info.data[0];
      },
      /** ____________________________________________________________________ API 商品管理から取得  */
      async productsInfo(info) {
        this.product = info.data[0];

      },
      /** __________________________________________________________________________ カートに追加 */
      addCart() {

        // 存在するか確認
        const findItem = this.$store.state.cartItems.find(item => item.no == this.product.no)


        if(findItem){

          // TODO 個数を増やす


          this.$router.push("/cart/");

        }else {

          // カートに商品を追加
          this.product.num = 1
          this.$store.state.cartItems.push(this.product);

          // storeに保存
          this.$store.commit("ADD_CART_ITEMS", this.$store.state.cartItems)

          // カート追加前のアドレスを保存
          this.$store.state.orderInfo.beforePath  = this.$route.path
          this.$store.commit("SET_ORDER_INFO", this.$store.state.orderInfo)

          //ページ変更
          this.$router.push("/cart/");
        }

      },


    },


  }
</script>
<style scoped>
  @import "~@/styles/style.css";

  .v-text-field > .v-input__control > .v-input__slot::before{
    border-color: rgba(0, 0, 0, 0);
  }
  .btn-reserve button{
    width:100%;
    padding:32px;
  }
  .border-none {
    border:none!Important;
  }
  .card-pa0 {
    padding:0!important;
  }
  .icon-map i {
    color:#e60044;
    font-size:24px;
  }
  .v-text-field--filled {
    border-radius:0;
  }
  .select-menu {
    border-radius:0!important;
    height: 56px;
    overflow: hidden;
  }
  .btn-reserve button,
  .btn-reserve a
  {
    height: auto!important;
    padding-top: 24px!important;
    padding-bottom: 24px!Important;
    font-weight:bold;
  }
  .btn-register{
    text-align:center;
  }
  .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
    color: rgba(0, 0, 0, 0.87);
  }

  @media (max-width: 599px) {
    .sp-hide {
      display: none;
    }
  }
  .abrid-add {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 6em;
  }
  .abrid-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    height: 8em;
  }
  .p-category a {
    color:rgba(0, 0, 0, 0.87);
  }
</style>
